import { mapState, mapActions } from 'vuex'
import Vue from 'vue'
import { Empty } from 'vant'
import { Popup, Toast, Dialog } from 'vant'
import con from '../../utils/common'

Vue.use(Empty)
Vue.use(Toast)
Vue.use(Dialog)
export default {
  name: 'warranty',
  components: {},
  data () {
    return {
      active: 0,
      // 保单列表
      warrantyList: [],
      waitPayList: [],
      UnderwritingList: [],
      takeEffectList: [],
      oldTaskList:[],
      noback: true,
      phone: this.getUrlParam('phone')

    }
  },
  beforeCreate () {

  },
  created () {
    const noback1 = this.getUrlParam('noback')

    if (noback1 != '' && noback1 != undefined && noback1 != null) {
      this.noback = false
    } else {
      this.noback = true
    }
    const token = this.getUrlParam('token')
    if (token != '' && token != undefined && token != null) {
      localStorage.setItem('token', token)
    }
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
    })
    this.myWarranty1({
      bxOrderState: 99,
      formerYears:2
    }).then(res => {
      if (res.code == 200) {
        Toast.clear()
        this.warrantyList = res.list
      }

    })
  },
  methods: {
    ...mapActions('warranty', ['myWarranty1', 'queryorder', 'taOutLogin']),
    todetail (ids, types, orderType) {

      if (orderType != undefined && orderType != null && orderType != 'null' && orderType != '') {
        this.$router.push({ name: 'Wardetail', query: { id: ids, type: types, orderTypes: orderType } })
      } else {
        this.$router.push({ name: 'Wardetail', query: { id: ids, type: types, orderTypes: 'a' } })
        //orderType为undefined，传0和1之外的值
      }

    },
    back () {
      if (this.$route.query.jump == 0) {
        this.$router.push({ name: 'Mine' })
      } else {
        this.$router.go(-1)
      }

    },
    //退出登录
    outLogin () {
      Dialog.confirm({
        title: '系统提示',
        message: '确认退出登录!',
        confirmButtonText: '确定',
        confirmButtonColor: "#5d6d8e",
        showCancelButton: true,
        className: 'tishi'
      }).then(() => {
        this.taOutLogin({
        }).then(res => {
          if (res.code == 200) {
            window.location.href = con.bxLoginUrl

          }
        })

      })
        .catch(() => {
          // on cancel
        })


    },
    playApplication (id) {
      //申请理赔
      this.changeData()
      this.$router.push({ name: 'settlement', query: { bxId: id } })
    },
    waitPay (e) {
      if (e == 1) {
        this.myWarranty1({
          bxOrderState: 0,
          formerYears:2
        }).then(res => {
          if (res.code == 200) {
            // let arr = []
            // res.list.forEach((item)=>{
            //   if((item.productCode.includes('JWYCX')||item.productCode.includes('02IC'))&& item.status === 0){
            //
            //   }else{
            //     arr.push(item)
            //   }
            // })
            this.waitPayList = res.list
          }

        })

      } else if (e == 2) {

        this.myWarranty1({
          bxOrderState: 1,
          formerYears:2
        }).then(res => {
          if (res.code == 200) {
            this.UnderwritingList = res.list
          }

        })

      } else if (e == 3) {
        var list = []
        if (this.takeEffectList.length == 0) {
          Toast.loading({
            message: '加载中...',
            duration: 50000,
            forbidClick: true,
          })
        }
        this.myWarranty1({
          bxOrderState: 2,
          formerYears:2
        }).then(res => {
          Toast.clear()
          if (res.code == 200) {

                this.takeEffectList = list.concat(res.list)

          }
          Toast.clear()
        })

      }else if (e == 4) {
        var list = []
        if (this.oldTaskList.length == 0) {
          Toast.loading({
            message: '加载中...',
            duration: 0,
            forbidClick: true,
          })
        }
        this.myWarranty({
          // bxOrderState:2
          bxOrderState: 99,
          formerYears:1
        }).then(res => {
          if (res.code == 200) {
            this.oldTaskList = list.concat(res.list)
          }
          Toast.clear()
        })

      } else if (e == 0) {
        if (this.warrantyList.length == 0) {
          Toast.loading({
            message: '加载中...',
            duration: 50000,
            forbidClick: true,
          })
        }
        this.myWarranty1({
          bxOrderState: 99,
          formerYears:2
        }).then(res => {
          Toast.clear()

          if (res.code == 200) {
            // let arr = []
            // res.list.forEach((item)=>{
            //   if((item.productCode.includes('JWYCX')||item.productCode.includes('02IC'))  && item.status === 0){
            //
            //   }else{
            //     arr.push(item)
            //   }
            // })
            this.warrantyList = res.list
            Toast.clear()
          }

        })
      }

    },
    gopay (url) {
      window.location.href = url

    },
    queryPay (id) {
      Dialog.confirm({
        message: '确定取消订单吗？',
        className: 'sure'
      })
        .then(() => {
          this.queryorder({
            id: id
          }).then(res => {
            if (res.code == 200) {

              this.myWarranty1({
                bxOrderState: 0
              }).then(res => {
                if (res.code == 200) {

                  this.waitPayList = res.list
                }
              })

            }

          })
          // setTimeout(()=>{

          // },100)

        })
        .catch(() => {
          // on cancel
        })



    },
    queryPay1 (id) {
      var that = this
      Dialog.confirm({
        message: '确定取消订单吗？',
        className: 'sure'
      })
        .then(() => {
          that.queryorder({
            id: id
          }).then(res => {
            if (res.code == 200) {
              that.myWarranty1({
                bxOrderState: 99
              }).then(res => {
                if (res.code == 200) {

                  that.warrantyList = res.list
                }
              })

            } else {
              Toast(res.msg)

            }

          })
          this.myWarranty1({
            bxOrderState: 99
          }).then(res => {
            if (res.code == 200) {

              this.warrantyList = res.list
            }

          })
        })
        .catch(() => {
          // on cancel
        })


    },
    getUrlParam (name) {

      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    }




  },
}
